.is--aboutus {
    .rwd002-container {
        margin-top: 35px;
        @include media-down(md) {
            margin-top: 0;
        }
    }
}
.aboutus {
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    &__title {
        @include c-block-subTitle(center);
        margin-bottom: 35px;
        padding-bottom: 23px;
        font-size: calc(28 / 16 * $font-size-base);
        font-family: 'Hannari', $font-family-serif;
        font-feature-settings: 'palt';
        color: #a1896f;
        letter-spacing: 0.1em;
        line-height: calc(36 / 28);
        @include media-down(md) {
            margin-bottom: 22px;
            padding-bottom: 15px;
            font-size: calc(20 / 16 * $font-size-base);
        }
        &::before {
            right: 0;
            margin: auto;
        }
    }
    &__reservationBtnwrap {
        display: flex;
        justify-content: center;
    }
    &__reservationBtn {
        @include c-reservation-button();
    }
}
.aboutus-reform {
    &__detail {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 634px;
        margin: 0 auto;
        padding: 17px 5px;
        font-size: calc(14 / 16 * $font-size-base);
        color: #2a2c2c;
        text-align: center;
        letter-spacing: 0.06em;
        line-height: calc(24 / 14);
        border: solid 1px #2a2c2c;
        border-radius: 3px;
        @include media-down(md) {
            margin-top: 20px;
            padding: 10px 3px;
        }
    }
    &__parts {
        img {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            width: 100vw;
            height: 68px;
            @include media-down(md) {
                width: 100%;
                height: 47px;
            }
        }
    }
    &__checkList {
        width: 100%;
        margin: 0 auto;
        padding: 0;
        list-style-type: none;
        @include media-up(md) {
            column-count: 2;
            column-gap: 35px;
            max-width: 623px;
        }
        ._item {
            position: relative;
            display: flex;
            align-items: center;
            height: 50px;
            // padding-left: 26px;
            padding-left: 3px;
            font-size: calc(15 / 16 * $font-size-base);
            color: #000;
            letter-spacing: 0.1em;
            border-bottom: solid 1px #6c6267;
            @include media-down(md) {
                height: 45px;
                font-size: calc(14 / 16 * $font-size-base);
            }
            &::before {
                content: '';
                // position: absolute;
                // transform: translateY(-50%);
                // top: 50%;
                // left: 3px;
                width: 14px;
                height: 12px;
                margin-right: 9px;
                // margin: auto;
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 13.55 11.68"><defs><style>.cls-1{fill:#9c8972;stroke-width:0px;}</style></defs><g id="_文字"><path class="cls-1" d="m12.43,0c-3.58,1.99-7.21,7.65-7.21,7.65L1.95,3.94l-1.95,1.86,4.73,5.88,1.72-.07C8.67,4.98,13.55.53,13.55.53l-1.13-.53Z"/></g></svg>'
                    )
                    center / 100% auto no-repeat;
            }
        }
    }
    &__bodyInner {
        width: 100%;
        max-width: 750px;
        margin-inline: auto;
    }
}
.aboutus-reform__lead {
    margin-bottom: 90px;
    padding-inline: map-get($spacers, 3);
    @include media-down(md) {
        margin-bottom: 45px;
    }
    .aboutus__title {
        margin-bottom: 50px;
        @include media-down(md) {
            margin-bottom: 30px;
        }
    }
    &__text {
        margin-bottom: 65px;
        font-size: calc(24 / 16 * $font-size-base);
        font-family: 'Hannari', $font-family-serif;
        text-align: center;
        color: #2a2c2c;
        letter-spacing: 0.1em;
        line-height: calc(31 / 24);
        @include media-down(md) {
            margin-bottom: 22px;
            font-size: calc(18 / 16 * $font-size-base);
            line-height: calc(25 / 18);
        }
        @include media-down(sm) {
            text-align: left;
            br {
                display: none;
            }
        }
    }
    &__body {
        display: grid;
        grid-template-columns: 400px 1fr;
        align-items: center;
        gap: 70px;
        @include media-down(lg) {
            gap: 30px;
        }
        @include media-down(md) {
            display: block;
        }
        ._img {
            @include media-down(md) {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
            }
            img {
                max-width: 100%;
                height: auto;
            }
        }
        ._text {
            margin-bottom: 27px;
            font-size: calc(14 / 16 * $font-size-base);
            font-family: $font-family-serif;
            color: #2a2c2c;
            letter-spacing: 0.1em;
            line-height: calc(30 / 14);
            @include media-down(md) {
                margin-bottom: 18px;
                letter-spacing: 0.12em;
            }
        }
    }
    .aboutus-reform__detail {
        margin-bottom: 10px;
    }
}
.aboutus-reform__container {
    overflow: hidden;
    position: relative;
    padding-top: 200px;
    padding-inline: map-get($spacers, 3);
    background: url(/assets/img/webp/feature/pho-feature-histoty-grad@2x.webp)
        no-repeat top 2px center/100% auto;
    @include media-down(md) {
        padding-top: 90px;
    }
    @include media-down(sm) {
        background-image: url(/assets/img/webp/pho-back_sp@2x.webp);
    }
    ._arrow {
        display: flex;
        justify-content: center;
        margin: 35px 0 23px;
        @include media-down(md) {
            margin-top: 30px;
        }
    }
    ._head {
        margin-bottom: 75px;
        @include media-down(md) {
            margin-bottom: 45px;
        }
    }
    &__img {
        margin-bottom: 50px;
        @include media-down(md) {
            margin-bottom: 30px;
        }
        img {
            width: 100%;
            height: auto;
        }
    }
    &__title {
        @include c-block-iconTitle(center);
        &._sp-left {
            @include media-down(sm) {
                justify-content: flex-start;
            }
        }
    }
}
.aboutus-reform__item {
    padding-top: 75px;
    padding-bottom: 75px;
    background: url(/assets/img/webp/aboutus/fig-border@2x.webp) no-repeat top
        center / auto 8px;
    @include media-down(md) {
        padding-top: 50px;
        padding-bottom: 50px;
    }
    &__body {
        @include media-up(md) {
            display: grid;
            grid-template-columns: 326px 1fr;
            gap: 42px;
            align-items: center;
            margin-top: 35px;
        }
        @include media-down(md) {
            margin-top: 23px;
        }
        ._img {
            @include media-down(md) {
                display: flex;
                justify-content: center;
                margin-bottom: 20px;
            }
            img {
                max-width: 100%;
                height: auto;
                @include media-down(sm) {
                    width: 100%;
                }
            }
        }
        ._title {
            margin-bottom: 17px;
            font-size: calc(16 / 16 * $font-size-base);
            font-family: $font-family-serif;
            font-feature-settings: 'palt';
            color: #2a2c2c;
            letter-spacing: 0.14em;
            line-height: calc(30 / 16);
            @include media-down(md) {
                margin-bottom: 14px;
            }
        }
    }
    ._text {
        margin: 0;
        font-size: calc(14 / 16 * $font-size-base);
        font-family: $font-family-serif;
        color: #2a2c2c;
        letter-spacing: 0.1em;
        line-height: calc(30 / 14);
        @include media-down(md) {
            letter-spacing: 0.12em;
        }
        &._center {
            text-align: center;
        }
    }
    .aboutus-reform__detail {
        margin-top: 45px;
        @include media-down(md) {
            margin-top: 15px;
        }
    }
    #detailimage {
        margin: 60px 0 15px;
        border: solid 2px #9c8972;
        @include media-up(md) {
            // min-height: 340px;
        }
        @include media-down(md) {
            // min-height: 140px;
            margin: 30px 0 10px;
        }
        .twentytwenty-handle {
            background-color: #fff;
            border-color: #9c8972;
            border-width: 2px;
            box-shadow: none;
            .twentytwenty-left-arrow,
            .twentytwenty-right-arrow {
                top: 0;
                bottom: 0;
                width: 5px;
                height: 10px;
                margin: auto;
                padding: 0;
                border: none;
            }
            .twentytwenty-left-arrow {
                left: 11px;
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.94 11.11"><defs><style>.cls-1{fill:#f8f7f5;stroke:#9c8972;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><g id="_文字"><path class="cls-1" d="m4.94,10.11L1,5.56,4.94,1"/></g></svg>'
                    )
                    center / auto 100% no-repeat;
            }
            .twentytwenty-right-arrow {
                right: 11px;
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 5.94 11.11"><defs><style>.cls-1{fill:#f8f7f5;stroke:#9c8972;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs><g id="_文字"><path class="cls-1" d="m1,1l3.94,4.55-3.94,4.56"/></g></svg>'
                    )
                    center / auto 100% no-repeat;
            }
        }
        .twentytwenty-handle:after,
        .twentytwenty-handle:before {
            width: 2px;
            background-color: #9c8972;
            box-shadow: none;
        }
        img {
            height: auto;
        }
    }
    ._tyuki {
        margin: 0;
        font-size: calc(12 / 16 * $font-size-base);
        color: #2a2c2c;
        letter-spacing: 0.14em;
        text-align: center;
    }
}
.aboutus-reform__sublist {
    margin: 0 0 30px;
    padding: 0;
    list-style-type: none;
    @include media-down(md) {
        margin-bottom: 20px;
    }
}
.aboutus-reform__sublist__item {
    display: flex;
    align-items: center;
    height: 140px;
    &:not(:last-of-type) {
        border-bottom: solid 1px #efefef;
    }
    ._icon {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 80px;
        @include media-down(md) {
            img {
                transform: translateX(-5px);
            }
        }
    }
    ._body {
        @include media-up(md) {
            display: flex;
            align-items: center;
        }
    }
    ._title {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        width: 210px;
        margin: 0;
        font-size: calc(24 / 16 * $font-size-base);
        font-family: 'Hannari', $font-family-serif;
        color: #444444;
        letter-spacing: 0.1em;
        line-height: calc(30 / 24);
        @include media-down(md) {
            width: 100%;
            margin-bottom: 4px;
            font-size: calc(20 / 16 * $font-size-base);
            br {
                display: none;
            }
        }
    }
    ._num {
        display: flex;
        justify-content: center;
        flex-shrink: 0;
        width: 50px;
        font-size: calc(15 / 16 * $font-size-base);
        font-family: 'Marcellus', $font-family-serif;
        font-weight: 400;
        color: #982d1f;
        letter-spacing: 0.14em;
        @include media-down(md) {
            width: 34px;
            justify-content: flex-start;
        }
    }
    ._text {
        margin: 0;
        font-size: calc(15 / 16 * $font-size-base);
        font-family: $font-family-serif;
        font-feature-settings: 'palt';
        color: #373737;
        letter-spacing: 0.14em;
        line-height: calc(25 / 14);
        @include media-down(md) {
            line-height: calc(22 / 14);
            br {
                display: none;
            }
        }
    }
}
.aboutus-cleaning {
    padding-inline: map-get($spacers, 3);
    &__inner {
        width: 100%;
        max-width: 750px;
        margin-inline: auto;
        padding-bottom: 60px;
        @include media-down(md) {
            padding-bottom: 45px;
        }
    }
    &__lead {
        margin-bottom: 23px;
        font-size: calc(24 / 16 * $font-size-base);
        font-family: 'Hannari', $font-family-serif;
        text-align: center;
        color: #2a2c2c;
        letter-spacing: 0.1em;
        line-height: calc(31 / 24);
        @include media-down(md) {
            margin-bottom: 16px;
            font-size: calc(20 / 16 * $font-size-base);
            line-height: calc(30 / 20);
        }
    }
    &__text {
        font-size: calc(14 / 16 * $font-size-base);
        font-family: $font-family-serif;
        letter-spacing: 0.14em;
        line-height: calc(30 / 14);
        @include media-down(md) {
            line-height: calc(26 / 14);
        }
    }
    &__smallText {
        margin: 0;
        font-size: calc(12 / 16 * $font-size-base);
        letter-spacing: 0.14em;
    }
    &__detail {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 480px;
        height: 60px;
        margin: 35px auto 0;
        font-size: calc(14 / 16 * $font-size-base);
        color: #2a2c2c;
        text-align: center;
        letter-spacing: 0.06em;
        line-height: calc(32 / 14);
        border: solid 1px #2a2c2c;
        border-radius: 3px;
        @include media-down(md) {
            margin-top: 20px;
            height: 50px;
        }
    }
    &__img {
        margin-bottom: 75px;
        @include media-down(md) {
            margin-bottom: 25px;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
    &__list {
        margin: 50px 0 60px;
        padding: 0;
        list-style-type: none;
        border-top: solid 1px #efefef;
        @include media-down(md) {
            margin-top: 43px;
            margin-bottom: 20px;
        }
    }
    &__item {
        display: flex;
        align-items: center;
        height: 60px;
        border-bottom: solid 1px #efefef;
        @include media-down(md) {
            height: 75px;
        }
        ._num {
            display: flex;
            justify-content: center;
            flex-shrink: 0;
            width: 50px;
            font-size: calc(15 / 16 * $font-size-base);
            font-family: 'Marcellus', $font-family-serif;
            font-weight: 400;
            color: #982d1f;
            letter-spacing: 0.14em;
            @include media-down(md) {
                width: 34px;
                justify-content: flex-start;
                font-size: calc(14 / 16 * $font-size-base);
            }
        }
        ._text {
            margin: 0;
            font-size: calc(15 / 16 * $font-size-base);
            font-family: $font-family-serif;
            font-feature-settings: 'palt';
            color: #373737;
            letter-spacing: 0.14em;
            line-height: calc(25 / 14);
        }
    }
    .sp-text {
        margin-bottom: 28px;
        font-size: calc(12 / 16 * $font-size-base);
        letter-spacing: 0.14em;
        line-height: calc(20 / 12);
    }
}
.aboutus-btn__list {
    @include media-up(md) {
        display: grid;
        grid-template-columns: 320px 320px;
        justify-content: center;
        margin-top: 75px;
    }
    @include media-down(md) {
        display: block;
        margin-top: 50px;
    }
}
.aboutus-btn__link {
    transition: $transition-base;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
    font-size: calc(18 / 16 * $font-size-base);
    font-family: 'Hannari', $font-family-serif;
    text-decoration: none !important;
    color: #fff !important;
    letter-spacing: 0.2em;
    line-height: 1;
    @include media-down(md) {
        justify-content: flex-start;
        width: 100%;
        height: 53px;
        padding-left: 34px;
        font-size: calc(14 / 16 * $font-size-base);
    }
    @media (hover: hover) {
        &:hover {
            &::before {
                right: 15px;
            }
        }
    }
    &::before {
        content: '';
        transition: $transition-base;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 21px;
        width: 10px;
        height: 4px;
        margin: auto;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.81 5.21"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;}</style></defs><g id="_レイヤー_1-2"><polyline class="cls-1" points="0 4.71 24.55 4.71 19.94 .36"/></g></svg>'
            )
            right center / auto 100% no-repeat;
        @include media-down(md) {
            right: 11px;
        }
    }
    span {
        position: relative;
        &::before {
            content: '';
            transition: $transition-base;
            position: absolute;
            top: 0;
            bottom: 0;
            left: -21px;
            width: 13px;
            height: 13px;
            margin: auto;
            background: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.17 12.17"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><g id="_文字"><rect class="cls-1" x="4.09" y=".45" width="3.99" height="3.99" rx=".92" ry=".92" transform="translate(.06 5.02) rotate(-45)"/><rect class="cls-1" x="4.09" y="7.73" width="3.99" height="3.99" rx=".92" ry=".92" transform="translate(-5.1 7.15) rotate(-45)"/><rect class="cls-1" x="7.73" y="4.09" width="3.99" height="3.99" rx=".92" ry=".92" transform="translate(7.15 -5.1) rotate(45)"/><rect class="cls-1" x=".45" y="4.09" width="3.99" height="3.99" rx=".92" ry=".92" transform="translate(5.02 .06) rotate(45)"/></g></svg>'
                )
                center / auto 100% no-repeat;
            @include media-down(md) {
                left: -12px;
                width: 7px;
                height: 7px;
            }
        }
    }
    &.reform {
        background-color: #33566e;
        @media (hover: hover) {
            &:hover {
                background-color: darken(#33566e, 10%);
            }
        }
    }
    &.cleaning {
        background-color: #a1896f;
        @media (hover: hover) {
            &:hover {
                background-color: darken(#a1896f, 10%);
            }
        }
    }
}
