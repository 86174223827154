.is--home {
    .rwd002-container {
        overflow: hidden;
        margin-top: 0;
        padding-bottom: 0;
    }
}
@keyframes scroll_line {
    from {
        transform: translateY(-25px);
    }
    to {
        transform: translateY(0);
    }
}
.mainvisual-wrap {
    overflow: hidden;
    position: relative;
    padding-bottom: 115px;
    &::before {
        content: '';
        position: absolute;
        bottom: 5px;
        left: 0;
        width: 100%;
        height: calc(100% - 250px);
        background: url(/assets/img/webp/home/pho-mainvisual-back@2x.webp)
            no-repeat bottom center / cover;
        @include media-down(lg) {
            z-index: 9;
            height: calc(100% - 570px);
            background: url(/assets/img/webp/home/pho-mainvisual-back_sp@2x.webp)
                no-repeat top center / cover;
        }
        @include media-down(md) {
            height: calc(100% - 475px);
        }
        @include media-down(sm) {
            top: 320px;
            height: calc(100% - 320px);
        }
    }
    &__parts {
        position: absolute;
        bottom: 0;
        left: 0;
        z-index: 9;
        img {
            width: 100vw;
            height: 47px;
            @include media-down(md) {
            }
        }
    }
    // &::after {
    //     content: '';
    //     position: absolute;
    //     bottom: 0;
    //     width: 100%;
    //     height: 100px;
    //     background: url(/assets/img/webp/home/fig-mainvisual-parts-bottom@2x.webp)
    //         no-repeat top center / 100% auto;
    //     @include media-down(lg) {
    //         z-index: 9;
    //     }
    //     @include media-down(md) {
    //         background: url(/assets/img/webp/home/fig-mainvisual-parts-bottom_sp@2x.webp)
    //             no-repeat bottom center / 100% auto;
    //     }
    // }
}
.mainvisual {
    position: relative;
    @include media-up(lg) {
        display: grid;
        grid-template-columns: 325px 1fr;
    }
    @include media-down(lg) {
        display: flex;
        flex-direction: column-reverse;
    }
    &__slider-pager {
        --swiper-theme-color: #{$primary};
        --swiper-pagination-bullet-vertical-gap: 1em;
        --swiper-pagination-bullet-size: 6px;
        --swiper-pagination-bullet-inactive-color: #{$white};
        --swiper-pagination-bullet-inactive-opacity: 0.6;
        position: absolute;
        top: inherit !important;
        right: 23px !important;
        bottom: 23px !important;
        left: inherit !important;
        z-index: 9;
        display: flex;
        justify-content: flex-end;
        transform: none !important;
        @include media-down(md) {
            display: none;
        }
        &.swiper-pagination-vertical {
            @include media-up(lg) {
                right: map-get($spacers, 3);
            }
            @include media-down(lg) {
                top: calc((100vh / 2) - 60px);
                right: map-get($spacers, 1);
            }
        }
        .swiper-pagination-bullet {
            position: relative;
            opacity: 1;
            width: 4px;
            height: 4px;
            margin: 0 9px !important;
            background: #fff;
        }
        .swiper-pagination-bullet-active {
            background-color: #fff;
            &::after {
                content: '';
                position: absolute;
                top: -7px;
                left: -7px;
                width: 18px;
                height: 18px;
                background-color: rgba(255, 255, 255, 0.4);
                border-radius: 50%;
            }
        }
    }
    &__head {
        position: relative;
        @include media-down(lg) {
            z-index: 9;
            padding-bottom: 85px;
        }
        .main-title {
            position: relative;
            z-index: 1;
            display: flex;
            justify-content: center;
            padding-top: 145px;
            @include media-down(lg) {
                padding-top: 40px;
            }
            @include media-down(sm) {
                padding-top: 20px;
            }
        }
        .sub-title {
            position: absolute;
            right: 80px;
            bottom: 60px;
            @include media-down(lg) {
                top: 80px;
                bottom: inherit;
                right: 0;
                left: 0;
                width: 36px;
                margin: auto;
                transform: translateX(-90px);
                img {
                    height: auto;
                }
            }
            @include media-down(sm) {
                top: 100px;
            }
        }
        &::before {
            content: '';
            position: absolute;
            top: 225px;
            width: 100%;
            height: 38px;
            background: url(/assets/img/webp/home/fig-mainvisual-parts-top@2x.webp)
                no-repeat bottom center / 100% auto;
            @include media-down(lg) {
                display: none;
            }
        }
    }
    &__scroll {
        position: absolute;
        bottom: 0;
        left: 20px;
        display: flex;
        align-items: center;
        writing-mode: vertical-rl;
        column-gap: 1px;
        font-size: calc(11 / 16 * $font-size-base);
        font-family: 'Marcellus', $font-family-serif;
        font-weight: 400;
        color: $primary;
        letter-spacing: 0.3em;
        line-height: 1;
        z-index: 1;
        @include media-down(lg) {
            display: none;
        }
        span {
            transform: translateY(-10px);
        }
        .bar {
            position: relative;
            overflow: hidden;
            width: 1px;
            height: 89px;
            &::before,
            &:after {
                content: '';
                position: absolute;
                bottom: 0;
            }
            &::before {
                height: 100%;
                width: 1px;
                background: #bab6b8;
                transform: translateY(-25px);
                animation: scroll_line 1200ms ease-in-out infinite alternate;
            }
            &:after {
                width: 1px;
                height: 16px;
                margin: auto;
                background: #af2d1f;
                transform: translateY(-25px);
                animation: scroll_line 1200ms ease-in-out infinite alternate;
            }
        }
    }
}
.mainvisual-slider {
    max-height: calc(100vh - 100px);
    .is-home-first-view & {
        // max-height: calc(100vh - 100px);
    }
    @include media-down(lg) {
        max-height: 600px;
    }
    @include media-down(md) {
        max-height: 500px;
    }
    @include media-down(sm) {
        max-height: 354px;
    }
    &__wrapper {
        // position: fixed;
        // top: 0;
        // left: 0;
        // z-index: -1;
        overflow: hidden;
        @include media-up(lg) {
            border-radius: 3px;
        }
    }
    &__item img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    .swiper-slide-active img,
    .swiper-slide-duplicate-active img,
    .swiper-slide-prev img {
        transform: scale(1.1);
        animation: zoomOut 8.4s linear 0s 1 normal both;
    }
}
@keyframes zoomOut {
    from {
        transform: scale(1.1);
    }
    to {
        transform: scale(1);
    }
}
.block-topicks {
    padding: 0 map-get($spacers, 2);
    @include media-down(lg) {
        position: relative;
        z-index: 9;
    }
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    &__title {
        @include c-block-title();
        @include media-down(lg) {
        }
    }
    &__btnwrap {
        display: flex;
        justify-content: flex-end;
        @include media-down(md) {
            justify-content: center;
        }
    }
    &__btn {
        @include c-more-button();
    }
}
.block-topicks__list {
    margin: 0 0 30px;
    padding: 0;
    list-style-type: none;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    @include media-down(lg) {
        gap: 30px 20px;
    }
    @include media-down(md) {
        grid-template-columns: repeat(2, 1fr);
    }
}
.block-topicks__item {
    overflow: hidden;
    position: relative;
    border-radius: 3px;
    a {
        transition: $transition-base;
        text-decoration: none !important;
        @media (hover: hover) {
            &:hover {
                .subject {
                    color: $secondary;
                }
                .cat {
                    color: $secondary;
                    border-color: $secondary;
                }
                .block-topicks__item-img {
                    img {
                        transform: scale(1.1);
                    }
                }
            }
        }
    }
    &-img {
        overflow: hidden;
        margin-bottom: 15px;
        aspect-ratio: 1;
        border-radius: 3px;
        img {
            transition: $transition-base;
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
    .cat {
        position: absolute;
        top: 0;
        left: 0;
        width: 83px;
        height: 22px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: calc(11 / 16 * $font-size-base);
        font-weight: bold;
        color: #fff !important;
        letter-spacing: 0.18em;
        background-color: $primary;
        border-radius: 0 0 3px 0;
        @include media-down(md) {
            width: 76px;
        }
    }
    .subject {
        transition: $transition-base;
        display: block;
        font-size: calc(14 / 16 * $font-size-base);
        font-family: $font-family-serif;
        color: #444444;
        letter-spacing: 0.14em;
        line-height: calc(22 / 14);
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        @include media-down(md) {
        }
    }
    .date {
        font-size: calc(10 / 16 * $font-size-base);
        font-family: 'Jost', $font-family-sans-serif;
        font-weight: 500;
        color: #7a7175;
        letter-spacing: 0.16em;
    }
}
.block-guide {
    position: relative;
    padding: 0 map-get($spacers, 3);
    @include media-down(md) {
        // overflow: hidden;
    }
    @include media-down(sm) {
        overflow: hidden;
        padding-top: 25px;
    }
    [class*='image'] {
        position: absolute;
        @include media-down(sm) {
            position: relative;
        }
    }
    .image1 {
        top: 70px;
        right: -1.75rem;
        height: auto;
        @include media-down(md) {
            top: 10px;
        }
        @include media-down(sm) {
            right: inherit;
            left: -1.75rem;
            width: 348px;
            transform: scale(1.03);
            transform-origin: left top;
        }
    }
    .image2 {
        bottom: -130px;
        left: -25px;
        height: auto;
        @media (max-width: 1000px) {
            left: -1.75rem;
        }
        @include media-down(md) {
            bottom: -100px;
            width: 180px;
        }
        @include media-down(sm) {
            display: none;
        }
    }
    &__inner {
        position: relative;
        display: flex;
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
        padding-top: 90px;
        padding-bottom: 135px;
        @media (max-width: 1000px) {
            display: block;
        }
        @include media-down(sm) {
            padding-top: 35px;
            padding-bottom: 65px;
        }
    }
    &__title {
        @include c-block-subTitle();
    }
    &__lead {
        position: relative;
        font-size: calc(24 / 16 * $font-size-base);
        font-family: 'Hannari', $font-family-serif;
        color: $text-color;
        letter-spacing: 0.16em;
        line-height: calc(42 / 24);
        @include media-down(md) {
            font-size: calc(20 / 16 * $font-size-base);
            line-height: calc(31 / 20);
        }
    }
    &__head {
        flex-shrink: 0;
        margin-right: 135px;
        @include media-down(xl) {
            margin-right: 40px;
        }
        @media (max-width: 1000px) {
            margin-right: 0;
        }
    }
    &__body {
        width: 100%;
        max-width: 450px;
        margin-top: 105px;
        @media (max-width: 1000px) {
            margin-top: 0;
        }
        @include media-down(sm) {
            max-width: 100%;
        }
    }
    &__text {
        margin-bottom: 30px;
        padding-right: 5px;
        font-size: calc(14 / 16 * $font-size-base);
        font-family: $font-family-serif;
        color: #444444;
        letter-spacing: 0.14em;
        line-height: calc(30 / 14);
        @include media-down(md) {
            margin-bottom: 20px;
            letter-spacing: 0.12em;
        }
    }
    &__btnwrap {
        display: flex;
        justify-content: flex-end;
    }
    &__btn {
        @include c-more-button();
    }
    &__back {
        width: calc(100% - 100px);
        height: 462px;
        margin-left: auto;
        background-attachment: fixed;
        background-image: url(/assets/img/webp/home/pho-feature-back@2x.webp);
        background-position: center;
        background-size: cover;
        @media (max-width: 1200px) {
            background-attachment: inherit;
            background-image: url(/assets/img/webp/home/pho-feature-back_sp@2x.webp);
        }
        @include media-down(md) {
            height: 320px;
        }
        @include media-down(sm) {
            width: 100%;
            height: 188px;
        }
    }
}
.block-feature {
    padding: 0 map-get($spacers, 3);
    @include media-down(md) {
    }
    &__inner {
        position: relative;
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
        @include media-up(md) {
            display: flex;
            justify-content: space-between;
            flex-direction: row-reverse;
            padding-top: 110px;
            padding-bottom: 70px;
        }
        @include media-down(md) {
            padding-bottom: 28px;
        }
    }
    &__img {
        position: relative;
        top: -170px;
        height: 0;
        // position: absolute;
        // top: -60px;
        // right: 0;
        // z-index: 1;
        @include media-down(lg) {
            width: 50%;
        }
        @include media-down(md) {
            top: inherit;
            margin: -42px auto 25px;
            width: 200px;
            height: auto;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
    &__title {
        @include c-block-subTitle();
        @include media-down(lg) {
        }
    }
    &__lead {
        position: relative;
        margin-bottom: 30px;
        font-size: calc(24 / 16 * $font-size-base);
        font-family: 'Hannari', $font-family-serif;
        color: $text-color;
        letter-spacing: 0.16em;
        line-height: calc(42 / 24);
        @include media-down(md) {
            margin-bottom: 20px;
            font-size: calc(20 / 16 * $font-size-base);
            line-height: calc(31 / 20);
        }
    }
    &__body {
        width: 100%;
        max-width: 450px;
        margin-right: 30px;
        @include media-down(md) {
            max-width: 100%;
            margin-right: 0;
        }
    }
    &__text {
        margin-bottom: 30px;
        font-size: calc(14 / 16 * $font-size-base);
        font-family: $font-family-serif;
        color: #444444;
        letter-spacing: 0.1em;
        line-height: calc(30 / 14);
        @include media-down(md) {
            margin-bottom: 20px;
            letter-spacing: 0.12em;
        }
    }
    &__btnwrap {
        display: flex;
        justify-content: flex-end;
    }
    &__btn {
        @include c-more-button();
    }
}
.block-products {
    position: relative;
    padding: 190px map-get($spacers, 3) 490px;
    background: url(/assets/img/webp/home/pho-products-back@2x.webp) no-repeat
        top center / cover;
    @include media-down(lg) {
        padding-bottom: 70px;
    }
    @include media-down(md) {
        padding-top: 120px;
    }
    @include media-down(sm) {
        background-image: url(/assets/img/webp/home/pho-products-back_sp@2x.webp);
    }
    &__parts {
        ._top,
        ._bottom {
            position: absolute;
            left: 0;
            z-index: 1;
            width: 100vw;
        }
        ._top {
            top: 0;
            height: 68px;
            @include media-down(md) {
                width: 100%;
                height: 47px;
            }
        }
        ._bottom {
            bottom: 0;
            height: 72px;
            @include media-down(lg) {
                display: none;
            }
        }
    }
    &__inner {
        position: relative;
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    &__container {
        // display: flex;
        // justify-content: space-between;
        display: grid;
        // gap: 0 115px;
        grid-template-columns: 390px 440px;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 1;
        margin-bottom: 80px;
        @include media-down(xl) {
            grid-template-columns: 450px 440px;
        }
        @media (max-width: 980px) {
            display: block;
        }
        @include media-down(md) {
            margin-bottom: 50px;
        }
    }
    &__visual {
        align-items: flex-end;
        display: flex;
        flex-direction: column;
        @media (max-width: 980px) {
            flex-direction: inherit;
            justify-content: center;
            align-items: flex-start;
            margin-bottom: 35px;
        }
        ._image {
            width: calc(((100vw - 960px) / 2) + 390px);
            @include media-down(xl) {
                width: calc(((100vw - 960px) / 2) + 450px);
            }
            @media (max-width: 1017px) {
                width: 108%;
            }
            @media (max-width: 980px) {
                width: 613px;
            }
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                @media (max-width: 980px) {
                    object-fit: inherit;
                }
            }
        }
    }
    &__body {
        width: 100%;
    }
    &__title {
        @include c-block-subTitle();
    }
    &__lead {
        position: relative;
        margin-bottom: 30px;
        font-size: calc(24 / 16 * $font-size-base);
        font-family: 'Hannari', $font-family-serif;
        color: $text-color;
        letter-spacing: 0.16em;
        line-height: calc(42 / 24);
        @include media-down(md) {
            margin-bottom: 20px;
            font-size: calc(20 / 16 * $font-size-base);
            line-height: calc(31 / 20);
        }
    }
    &__text {
        margin-bottom: 30px;
        font-size: calc(14 / 16 * $font-size-base);
        font-family: $font-family-serif;
        color: #444444;
        letter-spacing: 0.1em;
        line-height: calc(30 / 14);
        @include media-down(md) {
            margin-bottom: 20px;
            letter-spacing: 0.12em;
        }
    }
    &__btnwrap {
        display: flex;
        justify-content: flex-end;
    }
    &__btn {
        @include c-more-button();
    }
    &__bnr {
        display: flex;
        justify-content: center;
        a {
            transition: $transition-base;
            display: block;
            width: max-content;
            @media (hover: hover) {
                &:hover {
                    opacity: 0.8;
                }
            }
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
}
.block-aboutus {
    position: relative;
    z-index: 1;
    @include media-up(lg) {
        margin-top: -380px;
        padding-left: 50px;
        padding-right: 50px;
    }
    &__container {
        @include media-up(lg) {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
        }
        ._item {
            position: relative;
            overflow: hidden;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            @include media-up(lg) {
                height: 400px;
            }
            @include media-down(lg) {
                width: 100%;
                height: 350px;
            }
            @include media-down(sm) {
                height: 230px;
            }
            @media (hover: hover) {
                &:hover {
                    ._image {
                        transform: scale(1.1);
                    }
                    &::after {
                        right: 15px;
                    }
                }
            }
            &::after {
                content: '';
                transition: $transition-base;
                position: absolute;
                bottom: 15px;
                right: 20px;
                width: 26px;
                height: 5px;
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.81 5.21"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;}</style></defs><g id="_レイヤー_1-2"><polyline class="cls-1" points="0 4.71 24.55 4.71 19.94 .36"/></g></svg>'
                    )
                    bottom right / 100% auto no-repeat;
            }
        }
        ._title {
            position: relative;
            z-index: 1;
            width: 280px;
            margin-bottom: 15px;
            padding-bottom: 15px;
            font-size: calc(28 / 16 * $font-size-base);
            font-family: 'Hannari', $font-family-serif;
            text-align: center;
            color: #fff;
            letter-spacing: 0.04em;
            border-bottom: solid 1px #fff;
        }
        ._text {
            position: relative;
            z-index: 1;
            font-size: calc(14 / 16 * $font-size-base);
            font-family: $font-family-serif;
            color: #fff;
            letter-spacing: 0.14em;
        }
        ._image {
            transition: $transition-base;
            position: absolute;
            top: 0;
            left: 0;
            object-fit: cover;
            object-position: center;
            width: 100%;
            height: 100%;
        }
    }
}
.block-shop {
    padding: 130px map-get($spacers, 3) 100px;
    @include media-down(md) {
        padding-top: 50px;
        padding-bottom: 90px;
    }
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    &__container {
        margin-bottom: 60px;
        @include media-up(lg) {
            display: grid;
            grid-template-columns: 1fr 445px;
            gap: 80px;
        }
        @include media-down(md) {
            margin-bottom: 50px;
        }
    }
    &__img {
        @include media-down(lg) {
            display: flex;
            justify-content: center;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
    &__body {
        width: 100%;
        padding-top: 25px;
        @include media-down(md) {
            padding-top: 40px;
        }
    }
    &__title {
        @include c-block-subTitle();
    }
    &__lead {
        position: relative;
        margin-bottom: 30px;
        font-size: calc(24 / 16 * $font-size-base);
        font-family: 'Hannari', $font-family-serif;
        color: $text-color;
        letter-spacing: 0.16em;
        line-height: calc(42 / 24);
        @include media-down(md) {
            margin-bottom: 20px;
            font-size: calc(20 / 16 * $font-size-base);
            line-height: calc(31 / 20);
        }
    }
    &__text {
        margin-bottom: 30px;
        font-size: calc(14 / 16 * $font-size-base);
        font-family: $font-family-serif;
        color: #444444;
        letter-spacing: 0.1em;
        line-height: calc(30 / 14);
        @include media-down(md) {
            margin-bottom: 20px;
            letter-spacing: 0.12em;
        }
    }
    &__btnwrap {
        display: flex;
        justify-content: flex-end;
    }
    &__btn {
        @include c-more-button();
    }
    &__reservationBtnwrap {
        display: flex;
        justify-content: center;
    }
    &__reservationBtn {
        @include c-reservation-button();
    }
}
