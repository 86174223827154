@function svg-right-angle($theme: $primary) {
    @return svgUrlFunc(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$theme}" d="M96 480c-8.188 0-16.38-3.125-22.62-9.375c-12.5-12.5-12.5-32.75 0-45.25L242.8 256L73.38 86.63c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0l192 192c12.5 12.5 12.5 32.75 0 45.25l-192 192C112.4 476.9 104.2 480 96 480z"/></svg>'
    );
}

@function svg-bottom-angle($theme: $body-color) {
    @return svgUrlFunc(
        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><!--! Font Awesome Pro 6.0.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. --><path fill="#{$theme}" d="M192 384c-8.188 0-16.38-3.125-22.62-9.375l-160-160c-12.5-12.5-12.5-32.75 0-45.25s32.75-12.5 45.25 0L192 306.8l137.4-137.4c12.5-12.5 32.75-12.5 45.25 0s12.5 32.75 0 45.25l-160 160C208.4 380.9 200.2 384 192 384z"/></svg>'
    );
}

.btn-icon-right-angle {
    position: relative;
    &:after {
        position: absolute;
        content: '';
        top: calc(50% - 0.3em);
        right: 1em;
        width: 1em;
        height: 0.6em;
        background: center / contain no-repeat;
        background-image: svg-right-angle();
    }
    &:hover:after,
    &:focus:after {
        background-image: svg-right-angle($white);
    }
}

.btn-icon-right-angle-white {
    @extend .btn-icon-right-angle;
    &:after {
        background-image: svg-right-angle($white);
    }
}

.btn-icon-bottom-angle {
    position: relative;
    &:after {
        position: absolute;
        content: '';
        top: calc(50% - 0.3em);
        right: 0;
        width: 2em;
        height: 0.6em;
        transition: all 0.5s ease-in-out;
        background: center / contain no-repeat;
        background-image: svg-bottom-angle();
        transform: rotateX(180deg);
    }
    &.collapsed:after {
        transform: rotateX(0deg);
    }
}

@mixin c-more-button() {
    transition: $transition-base;
    position: relative;
    width: 116px;
    padding-bottom: 12px;
    font-size: calc(13 / 16 * $font-size-base);
    font-family: 'Marcellus', $font-family-serif;
    font-weight: 400;
    color: #af2d1f !important;
    text-decoration: none !important;
    letter-spacing: 0.14em;
    line-height: 1;
    &::before {
        content: '';
        transition: $transition-base;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 6px;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 369.22 5.94"><defs><style>.cls-1{fill:none;stroke:#af2d1f;stroke-miterlimit:10;}</style></defs><g id="_文字"><polyline class="cls-1" points="0 5.44 367.96 5.44 362.57 .36"/></g></svg>'
            )
            right bottom / auto 100% no-repeat;
        @include media-down(md) {
            right: 16px;
        }
    }
    @media (hover: hover) {
        &:hover,
        &:focus {
            color: #5a4048 !important;
            &::before {
                width: calc(100% + 5px);
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 369.22 5.94"><defs><style>.cls-1{fill:none;stroke:#5a4048;stroke-miterlimit:10;}</style></defs><g id="_文字"><polyline class="cls-1" points="0 5.44 367.96 5.44 362.57 .36"/></g></svg>'
                    )
                    right bottom / auto 100% no-repeat;
            }
        }
    }
}
@mixin c-reservation-button() {
    transition: $transition-base;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 490px;
    height: 90px;
    font-size: calc(17 / 16 * $font-size-base);
    font-family: $font-family-serif;
    color: #af2d1f !important;
    text-decoration: none !important;
    letter-spacing: 0.14em;
    line-height: 1;
    border: solid 1px #af2d1f;
    .icon {
        transition: $transition-base;
        display: block;
        width: 27px;
        height: 26px;
        margin-right: 15px;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.91 26.98"><defs><style>.cls-1{fill:none;stroke:#a72013;stroke-miterlimit:10;}</style></defs><g id="_レイヤー_1-2"><polyline class="cls-1" points="4.33 4.12 .5 4.12 .5 26.48 27.41 26.48 27.41 4.12 23.74 4.12"/><rect class="cls-1" x="4.13" y="7.94" width="2.91" height="2.91"/><rect class="cls-1" x="9.71" y="7.94" width="2.91" height="2.91"/><rect class="cls-1" x="15.29" y="7.94" width="2.91" height="2.91"/><rect class="cls-1" x="20.87" y="7.94" width="2.91" height="2.91"/><rect class="cls-1" x="4.13" y="13.85" width="2.91" height="2.91"/><rect class="cls-1" x="9.71" y="13.85" width="2.91" height="2.91"/><rect class="cls-1" x="15.29" y="13.85" width="2.91" height="2.91"/><rect class="cls-1" x="20.87" y="13.85" width="2.91" height="2.91"/><rect class="cls-1" x="4.13" y="19.75" width="2.91" height="2.91"/><rect class="cls-1" x="9.71" y="19.75" width="2.91" height="2.91"/><rect class="cls-1" x="15.29" y="19.75" width="2.91" height="2.91"/><rect class="cls-1" x="20.87" y="19.75" width="2.91" height="2.91"/><rect class="cls-1" x="5.34" y=".5" width="2.27" height="5.1"/><rect class="cls-1" x="20.3" y=".5" width="2.27" height="5.1"/><line class="cls-1" x1="19.21" y1="4.12" x2="8.7" y2="4.12"/></g></svg>'
            )
            bottom right / 100% auto no-repeat;
    }
    &::before {
        content: '';
        transition: $transition-base;
        position: absolute;
        bottom: 12px;
        right: 12px;
        width: 26px;
        height: 5px;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.81 5.21"><defs><style>.cls-1{fill:none;stroke:#a72013;stroke-miterlimit:10;}</style></defs><g id="_レイヤー_1-2"><polyline class="cls-1" points="0 4.71 24.55 4.71 19.94 .36"/></g></svg>'
            )
            bottom right / 100% auto no-repeat;
        @include media-down(md) {
        }
    }
    @media (hover: hover) {
        &:hover,
        &:focus {
            color: #fff !important;
            background: #af2d1f;
            .icon {
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27.91 26.98"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;}</style></defs><g id="_レイヤー_1-2"><polyline class="cls-1" points="4.33 4.12 .5 4.12 .5 26.48 27.41 26.48 27.41 4.12 23.74 4.12"/><rect class="cls-1" x="4.13" y="7.94" width="2.91" height="2.91"/><rect class="cls-1" x="9.71" y="7.94" width="2.91" height="2.91"/><rect class="cls-1" x="15.29" y="7.94" width="2.91" height="2.91"/><rect class="cls-1" x="20.87" y="7.94" width="2.91" height="2.91"/><rect class="cls-1" x="4.13" y="13.85" width="2.91" height="2.91"/><rect class="cls-1" x="9.71" y="13.85" width="2.91" height="2.91"/><rect class="cls-1" x="15.29" y="13.85" width="2.91" height="2.91"/><rect class="cls-1" x="20.87" y="13.85" width="2.91" height="2.91"/><rect class="cls-1" x="4.13" y="19.75" width="2.91" height="2.91"/><rect class="cls-1" x="9.71" y="19.75" width="2.91" height="2.91"/><rect class="cls-1" x="15.29" y="19.75" width="2.91" height="2.91"/><rect class="cls-1" x="20.87" y="19.75" width="2.91" height="2.91"/><rect class="cls-1" x="5.34" y=".5" width="2.27" height="5.1"/><rect class="cls-1" x="20.3" y=".5" width="2.27" height="5.1"/><line class="cls-1" x1="19.21" y1="4.12" x2="8.7" y2="4.12"/></g></svg>'
                    )
                    bottom right / 100% auto no-repeat;
            }
            &::before {
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.81 5.21"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;}</style></defs><g id="_レイヤー_1-2"><polyline class="cls-1" points="0 4.71 24.55 4.71 19.94 .36"/></g></svg>'
                    )
                    bottom right / 100% auto no-repeat;
            }
        }
    }
}
@mixin c-contact-button() {
    transition: $transition-base;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 226px;
    height: 50px;
    font-size: calc(16 / 16 * $font-size-base);
    font-family: 'Marcellus', $font-family-serif;
    font-weight: 400;
    color: #982d1f !important;
    text-decoration: none !important;
    letter-spacing: 0.20em;
    line-height: 1;
    background-color: #fff;
    border: solid 1px #fff;
    border-radius: 3px;
    .icon {
      margin-right: 15px;
      width: 16px;
    }
    @media (hover: hover) {
        &:hover,
        &:focus {
            color: #fff !important;
            background-color: transparent;
        }
    }
}
