.rwd002-container {
  margin-top: map-get($spacers, 6);
  padding-bottom: 55px;
  @include media-down(lg) {
    margin-top: map-get($spacers, 3);
  }
}

.is--topicks.is--detail,
.is--module-form {
  .rwd002-container {
    padding-bottom: 0;
  }
}
