@mixin table-typeA {
    width: 100%;
    font-size: calc(14 / 16 * $font-size-base);
    font-feature-settings: 'palt';
    tr {
        &:nth-of-type(odd) {
            background-color: #f4f4f4;
        }
    }
    th,
    td {
        padding-top: 25px;
        padding-bottom: 25px;
        padding-right: 15px;
        vertical-align: top;
        color: #2a2c2c;
        @include media-down(md) {
            display: block;
        }
    }
    th {
        padding-left: 50px;
        font-family: $font-family-serif;
        font-weight: normal;
        white-space: nowrap;
        letter-spacing: 0.28em;
        @include media-up(md) {
            width: 230px;
        }
        @include media-down(md) {
            width: 100%;
            padding: 25px 15px 0 25px;
            // border-width: 0;
            // padding-bottom: 0;
        }
    }
    td {
        letter-spacing: 0.13em;
        @include media-up(md) {
            width: calc(100% - 230px);
        }
        @include media-down(md) {
            width: 100%;
            padding: 0 15px 25px 25px;
        }
    }
}
