.is--products.is--list {
    .rwd002-container {
        margin-top: 0;
        padding-bottom: 0;
    }
    .rwd002-list-content__inner {
        max-width: 1150px;
    }
    .rwd002-list-content {
        margin-top: 65px;
        @include media-down(md) {
            margin-top: 35px;
        }
    }
    .rwd002-list-content__list {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 32px 53px;
        margin: 0;
        @include media-down(lg) {
            grid-template-columns: repeat(3, 1fr);
        }
        @include media-down(md) {
            gap: 28px 9px;
        }
        @include media-down(sm) {
            grid-template-columns: repeat(2, 1fr);
        }
    }
    .rwd002-list-content__thumb {
        @include media-down(md) {
            margin-bottom: 13px;
        }
    }
    .rwd002-list-content__item {
        width: 100%;
        margin: 0;
        padding: 0;
        .title {
            @include c-block-iconTitle();
            margin: 0 !important;
            font-size: calc(17 / 16 * $font-size-base);
            font-weight: normal;
            transition: $transition-base;
            color: #6c6267;
            @include media-down(md) {
                font-size: calc(15 / 16 * $font-size-base);
            }
            span {
                display: inline-block;
                padding-left: 20px;
                &::before {
                    top: 0.3rem;
                    width: 11px;
                    height: 11px;
                }
            }
        }
    }
}
.is--products.is--detail {
    .rwd002-container {
        padding-bottom: 0;
    }
    .rwd002-pagination {
        margin-bottom: 75px;
        @include media-down(md) {
            margin-bottom: 45px;
        }
    }
    .editor-template {
        img {
            margin: 0 !important;
            padding: 0 !important;
        }
        .split-2 {
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 33px;
            margin-top: 55px;
            margin-bottom: 20px;
            @include media-down(md) {
                gap: 9px;
                margin-top: 35px;
                margin-bottom: 17px;
            }
        }
    }
    .rwd002-detail__head,
    .rwd002-detail__entry {
        max-width: 800px;
    }
    .rwd002-detail__title {
        position: relative;
        margin: 0;
        padding-bottom: 50px;
        font-size: calc(28 / 16 * $font-size-base);
        font-family: 'Hannari', $font-family-serif;
        font-weight: normal;
        color: #444444;
        letter-spacing: 0.2em;
        line-height: calc(25 / 20);
        text-align: center;
        border: none;
        @include media-down(md) {
            padding-bottom: 30px;
            font-size: calc(20 / 16 * $font-size-base);
        }
        &::before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 1px;
            height: 45px;
            margin: auto;
            background-color: #444444;
            @include media-down(md) {
                height: 25px;
            }
        }
    }
    .rwd002-detail__image {
        width: 100%;
        height: 100%;
        aspect-ratio: 800 / 506;
        margin-bottom: 40px;
        @include media-down(md) {
            margin-bottom: 20px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
    .rwd002-detail__entry {
        font-size: calc(14 / 16 * $font-size-base);
        font-family: $font-family-serif;
        color: #2a2c2c;
        letter-spacing: 0.14em;
        line-height: calc(30 / 14);
        @include media-down(md) {
            letter-spacing: 0.12em;
            line-height: calc(26 / 14);
        }
        h2,
        h3,
        h4,
        h5,
        h6 {
            margin-bottom: 20px;
            font-size: calc(22 / 16 * $font-size-base);
            font-family: 'Hannari', $font-family-serif;
            color: #a1896f;
            letter-spacing: 0.2em;
            line-height: calc(36 / 22);
            @include media-down(md) {
                margin-bottom: 15px;
                font-size: calc(18 / 16 * $font-size-base);
                line-height: calc(26 / 18);
            }
        }
        p {
            font-size: calc(14 / 16 * $font-size-base);
            font-family: $font-family-serif;
            color: #2a2c2c;
            letter-spacing: 0.14em;
            line-height: calc(30 / 14);
            @include media-down(md) {
                letter-spacing: 0.12em;
                line-height: calc(26 / 14);
            }
        }
    }
}

// AIRページ
.is--products.is--article-25 {
    .rwd002-detail {
        margin-top: 0;
        padding: 0;
    }
    .rwd002-detail__head {
        display: none;
    }
    .rwd002-detail__entry {
        max-width: 100%;
    }
}
.products-air {
    position: relative;
    overflow: hidden;
    margin-top: -50px;
    padding: 150px map-get($spacers, 2) 185px;
    background: url(/assets/img/webp/products/pho-air-back@2x.webp) no-repeat
        top 93px center / 99% auto;
    @include media-down(lg) {
        padding-bottom: 120px;
    }
    @include media-down(md) {
        padding-top: 95px;
    }
    @include media-down(sm) {
        background-image: url(/assets/img/webp/products/pho-air-back_sp@2x.webp);
        background-position: top 120px center;
    }
    &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 3px;
        left: 0;
        width: 100%;
        height: calc(100% - 6px);
        background-color: #050103;
    }
    &__parts {
        img {
            position: absolute;
            left: 0;
            z-index: 1;
            width: 100vw;
            height: 68px;
            @include media-down(md) {
                width: 100%;
                height: 47px;
            }
        }
        ._top {
            img {
                top: 0;
            }
        }
        ._bottom {
            img {
                bottom: 0;
                transform: rotate(180deg);
            }
        }
    }
    ._logo {
        display: flex;
        justify-content: center;
        margin: 0 !important;
        @include media-down(md) {
            margin-bottom: 10px !important;
        }
        img {
            @include media-down(md) {
                width: 175px;
                height: auto;
            }
        }
    }
    &__lead {
        width: 100%;
        max-width: 1200px;
        margin-inline: auto;
        padding-bottom: 70px;
        background: url(/assets/img/webp/aboutus/fig-border@2x.webp) no-repeat
            bottom center/auto 8px;
        @include media-down(md) {
            padding-bottom: 40px;
        }
        ._visual {
            display: grid;
            grid-template-columns: 560px 1fr;
            gap: 50px;
            margin-top: -15px;
            margin-bottom: 15px;
            padding-left: 15px;
            @media (max-width: 1040px) {
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: inherit;
                margin-top: 0;
            }
            @include media-down(md) {
                padding-left: 0;
            }
            ._image {
                @include media-down(sm) {
                    transform: translateX(-5px);
                    width: calc(100% + 5px);
                }
                img {
                    max-width: 100%;
                    height: auto;
                }
            }
            ._head {
                display: flex;
                flex-direction: column;
                align-items: center;
                width: max-content;
                padding-top: 60px;
                @media (max-width: 1040px) {
                    padding-top: 0;
                }
            }
            ._title {
                margin-bottom: 25px;
                font-size: calc(30 / 16 * $font-size-base);
                text-align: center;
                color: #fff !important;
            }
            ._text {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 296px;
                height: 30px;
                font-family: $font-family-sans-serif !important;
                font-size: calc(14 / 16 * $font-size-base);
                font-weight: bold;
                color: #444444;
                background-color: #fff;
            }
        }
        &__title {
            font-size: calc(24 / 16 * $font-size-base) !important;
            font-family: 'Hannari', $font-family-serif;
            font-feature-settings: normal;
            text-align: center;
            color: #fff !important;
            letter-spacing: 0.1em;
            line-height: calc(31 / 24);
            @include media-down(md) {
                font-size: calc(20 / 16 * $font-size-base) !important;
            }
        }
        &__text {
            font-feature-settings: normal;
            text-align: center;
            color: #fff !important;
            line-height: calc(30 / 14);
            @include media-down(md) {
                text-align: justify;
                line-height: calc(24 / 14);
            }
            &:not(:last-of-type) {
                margin-bottom: 25px;
                @include media-down(md) {
                    margin-bottom: 0;
                }
            }
            &._center {
                text-align: center;
            }
        }
    }
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
        padding-top: 80px;
        @include media-down(md) {
            padding-top: 40px;
        }
    }
    &__inner2 {
        padding-left: map-get($spacers, 2);
        padding-right: map-get($spacers, 2);
    }
    &__fig {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 55px;
        margin-bottom: 75px;
        @include media-down(md) {
            margin-top: 50px;
            margin-bottom: 50px;
        }
        p {
            margin-top: 30px;
            margin-bottom: 0;
            @include media-down(md) {
                margin-top: 20px;
            }
        }
    }
    &__point {
        display: grid;
        gap: 30px;
        @include media-down(lg) {
            gap: 15px;
        }
        @include media-up(md) {
            grid-template-columns: repeat(2, 1fr);
        }
        ._item {
            padding: 40px 55px;
            border: solid 1px #fff;
            @include media-down(xl) {
                padding: 40px;
            }
            @include media-down(lg) {
                padding: 35px 20px;
            }
        }
        ._title {
            margin-bottom: 15px !important;
            font-size: calc(24 / 16 * $font-size-base) !important;
            font-family: 'Hannari', $font-family-serif;
            text-align: center;
            color: #fff !important;
            line-height: 1 !important;
            @include media-down(md) {
                font-size: calc(20 / 16 * $font-size-base) !important;
            }
        }
        ._text {
            margin-bottom: 23px;
            font-size: calc(14 / 16 * $font-size-base) !important;
            font-family: $font-family-serif;
            font-feature-settings: normal;
            color: #fff !important;
            letter-spacing: 0.14em;
            line-height: calc(24 / 14);
            @include media-up(md) {
                min-height: 90px;
            }
        }
        ._image {
            display: grid;
            flex-direction: column;
            grid-template-columns: repeat(2, 1fr);
            @include media-down(lg) {
                gap: 15px;
            }
            figure {
                margin: 0;
            }
            img {
                display: block;
                margin: auto;
                @include media-down(sm) {
                    width: 100%;
                    height: auto;
                }
            }
            figcaption {
                margin-top: 10px;
                font-size: calc(12 / 16 * $font-size-base);
                font-family: $font-family-sans-serif;
                text-align: center;
                color: #fff;
                letter-spacing: 0.14em;
                line-height: calc(22 / 12);
                @include media-down(md) {
                    font-size: calc(11 / 16 * $font-size-base);
                    line-height: calc(19 / 11);
                }
            }
        }
    }
    &__title {
        @include c-block-subTitle(center);
        margin-bottom: 35px;
        padding-bottom: 23px;
        font-size: calc(28 / 16 * $font-size-base);
        font-family: 'Hannari', $font-family-serif;
        font-feature-settings: 'palt';
        color: #a1896f;
        letter-spacing: 0.1em;
        line-height: calc(36 / 28);
        @include media-down(md) {
            margin-bottom: 22px;
            padding-bottom: 15px;
            font-size: calc(20 / 16 * $font-size-base);
        }
        &::before {
            right: 0;
            margin: auto;
        }
    }
    &__reservationBtnwrap {
        display: flex;
        justify-content: center;
    }
    &__reservationBtn {
        @include c-reservation-button();
    }
}
.products-air__experience {
    padding: 70px map-get($spacers, 3);
    @include media-down(md) {
        padding: 25px map-get($spacers, 3) 40px;
    }
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    &__img {
        display: grid;
        gap: 15px;
        justify-content: space-between;
        margin: 50px 0 40px;
        @include media-up(sm) {
            grid-template-columns: repeat(2, 1fr);
        }
        @include media-down(md) {
            gap: 18px;
            margin: 28px 0 15px;
        }
    }
    &__text {
        margin-bottom: 40px;
        font-feature-settings: normal;
        text-align: center;
        color: #2a2c2c !important;
        line-height: calc(30 / 14);
        @include media-down(md) {
            text-align: justify;
            line-height: calc(24 / 14);
        }
    }
}
.products-detail__ohter {
    padding-inline: map-get($spacers, 3);
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    ._title {
        margin-bottom: 45px;
        font-size: calc(14 / 16 * $font-size-base);
        font-weight: bold;
        color: #332a23;
        text-align: center;
        letter-spacing: 0.1em;
        @include media-down(md) {
            margin-bottom: 25px;
        }
    }
}
.products-detail-list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 53px;
    @include media-down(lg) {
        gap: 30px;
    }
    @include media-down(md) {
        gap: 28px 9px;
    }
    @include media-down(sm) {
        grid-template-columns: repeat(2, 1fr);
    }
}
.products-detail-item {
    text-decoration: none !important;
    &__img {
        overflow: hidden;
        aspect-ratio: 1;
        width: 100%;
        margin-bottom: 17px;
        @include media-down(md) {
            margin-bottom: 15px;
        }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
            transition: $transition-base;
        }
    }
    &:hover,
    &:focus {
        img {
            transform: scale(1.1);
        }
        .subject {
            color: #a1896f;
        }
    }
    .subject {
        @include c-block-iconTitle();
        margin: 0 !important;
        font-size: calc(17 / 16 * $font-size-base);
        transition: $transition-base;
        color: #6c6267;
        @include media-down(md) {
            font-size: calc(15 / 16 * $font-size-base);
        }
        span {
            padding-left: 20px;
            &::before {
                top: 0.3rem;
                width: 11px;
                height: 11px;
            }
        }
    }
}
