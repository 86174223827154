.shop-about {
    padding: 0 0 110px;
    @include media-down(md) {
        padding-bottom: 70px;
    }
    &__inner {
        width: 100%;
        max-width: 850px;
        margin-inline: auto;
    }
    &__table {
        @include table-typeA;
        margin-bottom: 18px;
    }
    ._tyuki {
        margin: 0;
        font-size: calc(11 / 16 * $font-size-base);
        text-align: right;
        letter-spacing: 0.15em;
        @include media-down(lg) {
            margin-right: map-get($spacers, 2);
        }
    }
    &__payment-wrap {
        padding-left: map-get($spacers, 3);
        padding-right: map-get($spacers, 3);
    }
    &__payment {
        display: flex;
        align-items: center;
        gap: 40px;
        width: 100%;
        max-width: 850px;
        margin-top: 60px;
        margin: 60px auto 0;
        padding: 30px 50px;
        border: solid 3px #f4f4f4;
        @include media-down(md) {
            flex-direction: column;
            justify-content: center;
            gap: inherit;
            padding: 19px;
            text-align: center
        }
        dt {
            @include c-block-iconTitle();
            margin: 0;
            font-weight: normal;
            font-size: calc(18 / 16 * $font-size-base);
            color: #6c6267;
            letter-spacing: 0.14em;
            @include media-down(md) {
                margin-bottom: 10px;
            }
            span {
                &::before {
                    top: 0.3rem;
                }
            }
        }
        dd {
            margin: 0;
            font-size: calc(15 / 16 * $font-size-base);
            font-weight: bold;
        }
    }
}
.shop-loop {
    position: relative;
    z-index: 1;
    width: 100%;
    height: 528px;
    // margin-top: -175px;
    background-image: url(/assets/img/webp/shop/gallery-img@2x.webp);
    background-repeat: repeat-x;
    background-size: auto 528px;
    animation: loop 60s linear infinite;
    @include media-down(md) {
        height: 312px;
        background-size: auto 312px;
    }
}
@keyframes loop {
    from {
        background-position: 0 0;
    }
    to {
        background-position: -3638px 0;
    }
}
.shop-access {
    &__head {
        display: grid;
        grid-template-columns: 487px 1fr;
        height: 484px;
        @include media-down(xl) {
            grid-template-columns: 360px 1fr;
        }
        @include media-down(lg) {
            display: block;
            height: auto;
        }
        ._title {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            height: 100%;
            margin: 0;
            color: #fff;
            background-color: #9c8972;
            @include media-down(lg) {
                height: 200px;
            }
            @include media-down(md) {
                height: 172px;
            }
            .ja {
                margin-bottom: 12px;
                font-size: calc(25 / 16 * $font-size-base);
                font-family: 'Hannari', $font-family-serif;
                letter-spacing: 0.14em;
            }
            .en {
                font-size: calc(11 / 16 * $font-size-base);
                font-family: 'Marcellus', $font-family-serif;
                font-weight: 400;
                letter-spacing: 0.3em;
            }
        }
        ._map {
            @include media-down(lg) {
                height: 303px;
            }
            iframe {
                display: block;
                width: 100%;
                height: 100%;
            }
        }
    }
    &__container {
        padding: 90px map-get($spacers, 3) 0;
        @include media-down(md) {
            padding-top: 40px;
            padding-bottom: 20px;
        }
    }
    &__inner {
        width: 100%;
        max-width: 980px;
        margin-inline: auto;
    }
    &__map {
        display: flex;
        justify-content: center;
        margin-bottom: 45px;
        @include media-down(lg) {
            margin-bottom: 0;
        }
        img {
            max-width: 100%;
            height: auto;
        }
    }
    &__detail {
        margin-bottom: 40px;
        @include media-up(lg) {
            display: grid;
            grid-template-columns: 37.5% 28% 34.5%;
        }
        @include media-down(lg) {
            margin-bottom: 15px;
        }
        &__item {
            @include media-down(lg) {
                padding-top: 24px;
                padding-bottom: 24px;
            }
            &:not(:last-of-type) {
                @include media-up(lg) {
                    border-right: solid 1px #e1e1e1;
                }
                @include media-down(lg) {
                    border-bottom: solid 1px #e1e1e1;
                }
            }
            &:nth-of-type(2) {
                @include media-up(lg) {
                    padding-left: 18%;
                }
            }
            &:nth-of-type(3) {
                @include media-up(lg) {
                    padding-left: 16%;
                }
            }
            ._title {
                @include c-block-iconTitle();
                margin: 0 0 10px;
                font-weight: normal;
                color: #6c6267;
                letter-spacing: 0.14em;
                @include media-down(md) {
                    font-size: calc(18 / 16 * $font-size-base);
                }
                span {
                    &::before {
                        top: 0.4rem;
                    }
                }
            }
            ._text {
                margin: 0;
                font-size: calc(15 / 16 * $font-size-base);
                letter-spacing: 0.1em;
                line-height: 2;
                span {
                    color: #a72013;
                }
            }
            .btn-link {
                font-weight: normal;
                margin-top: 10px;
                padding-left: 0;
                padding-right: 8px;
                padding-bottom: 4px;
                font-size: calc(12 / 16 * $font-size-base);
                font-family: 'Roboto', $font-family-sans-serif;
                border-bottom: 1px solid;
                letter-spacing: 0.18em;
                transition: $transition-base;
                &:hover,
                &:focus {
                    text-decoration: none;
                    color: #a72013;
                }
            }
        }
    }
    &__reservationBtnwrap {
        display: flex;
        justify-content: center;
    }
    &__reservationBtn {
        @include c-reservation-button();
    }
}
