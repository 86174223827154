@keyframes up_down_line {
    0% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-8px);
    }
}

.rwd002-footerSocial {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 90px;
    margin-bottom: 90px;
    @include media-up(lg) {
        margin-top: 60px;
        margin-bottom: 60px;
    }
    &__title {
        letter-spacing: 0.16em;
        margin-right: 1em;
        font-family: 'Lato', $font-family-sans-serif;
    }
    &__btn {
        @extend .btn;
        font-size: 1.8em;
        margin: 0 0.1em;
        line-height: 1;
        transition: $transition-base;
    }
    &__btn.is-fb {
        &:hover,
        &:focus {
            color: $sns-facebook-bg;
        }
    }
    &__btn.is-tw {
        &:hover,
        &:focus {
            color: $sns-twitter-bg;
        }
    }
    &__btn.is-line {
        &:hover,
        &:focus {
            color: $sns-line-bg;
        }
    }
}

.rwd002-pagetop {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: -50px;
    width: 100px;
    height: 100px;
    font-size: calc(11 / 16 * $font-size-base);
    font-family: 'Marcellus', $font-family-serif;
    font-weight: 400;
    text-align: center;
    color: #444444;
    text-decoration: none;
    letter-spacing: 0.12em;
    line-height: 1;
    transform-origin: bottom;

    @include media-up(lg) {
        right: map-get($spacers, 5);
    }
    @include media-down(lg) {
        right: calc(50% - 50px);
    }
    &:before {
        content: '';
        display: block;
        width: 36px;
        height: 14px;
        margin-bottom: 4px;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 36.73 15.08"><defs><style>.cls-1{fill:none;stroke:#af2d1f;stroke-miterlimit:10;}</style></defs><g id="_レイヤー_1-2"><path class="cls-1" d="m.31,14.68L18.37.63l18.06,14.05"/></g></svg>'
            )
            no-repeat center / contain;
    }
    &:hover,
    &:focus {
        text-decoration: none;
        &:before {
            animation: up_down_line 0.9s infinite ease-in-out;
        }
    }
    &__wrap {
        width: 100%;
        z-index: 10;
        transition: $transition-base;
        @include media-up(lg) {
            position: fixed;
            position: sticky;
            bottom: 80px;
            margin: 0 auto;
            padding-left: map-get($spacers, 1);
            padding-right: map-get($spacers, 1);
        }
        @include media-down(lg) {
            position: relative;
        }
        &.hidden {
            transform: translateY(200px);
            opacity: 0;
        }
    }
}

.rwd002-footer {
    position: relative;
    overflow: hidden;
    color: $white;
    background: url(/assets/img/webp/pho-footer-back@2x.webp) no-repeat top 2px center / cover;
    @include media-up(md) {
        padding: 210px map-get($spacers, 5) map-get($spacers, 3);
    }
    @include media-down(lg) {
        padding-top: 160px;
    }
    @include media-down(md) {
        padding: 110px map-get($spacers, 3) map-get($spacers, 2);
    }
    @include media-down(sm) {
        background-image: url(/assets/img/webp/pho-footer-back_sp@2x.webp);
    }

    &__parts {
        position: absolute;
        top: -1px;
        left: 0;
        z-index: 1;
        img {
            width: 100vw;
            height: 68px;
            @include media-down(md) {
                height: 47px;
            }
        }
    }

    &__inner {
        max-width: 960px;
        margin: 0 auto;
        @include media-up(md) {
            display: grid;
            grid-template-columns: 1fr 60%;
            align-items: center;
        }
    }

    &__info {
        margin-bottom: auto;
        padding-top: 23px;
        padding-left: 40px;
        @include media-up(lg) {
            // flex-grow: 1;
            // padding-right: map-get($spacers, 3);
        }
        @include media-down(lg) {
            padding-left: 0;
            // margin-bottom: 2em;
        }
        @include media-down(md) {
            margin-bottom: 35px;
            padding-top: 0;
        }
        &__inner {
            display: flex;
            flex-direction: column;
            align-items: center;
            @include media-up(lg) {
                width: max-content;
            }
        }
        &__title {
            position: relative;
            margin-bottom: 15px;
            padding-bottom: 15px;
            font-size: calc(25 / 16 * $font-size-base);
            font-family: 'Marcellus', $font-family-serif;
            font-weight: 400;
            letter-spacing: 0.14em;
            line-height: 1;
            &::before {
                content: '';
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                width: 28px;
                height: 1px;
                margin: auto;
                background-color: #fff;
            }
        }
        &__lead {
            margin-bottom: 27px;
            font-size: calc(13 / 16 * $font-size-base);
            font-weight: bold;
            letter-spacing: 0.18em;
            line-height: 1;
        }
        .telbox {
        }
        .tel,
        .freetel {
            padding-left: 33px;
            font-size: calc(27 / 16 * $font-size-base);
            font-family: 'Marcellus', $font-family-serif;
            font-weight: 400;
            letter-spacing: 0.14em;
            line-height: 1;
            @include media-down(sm) {
                font-size: calc(24 / 16 * $font-size-base);
            }
            &::before {
                content: '';
                position: absolute;
                top: 0;
                bottom: 0;
                left: 0;
                margin: auto;
            }
        }
        .tel {
            position: relative;
            margin-bottom: 15px;
            &::before {
                width: 22px;
                height: 22px;
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28"><defs><style>.cls-1{fill:#fff;}.cls-2{fill:#982d1f;}</style></defs><g id="_文字"><circle class="cls-1" cx="14" cy="14" r="14"/><path class="cls-2" d="m10.01,7.36l1.66,1.66c.24.24.22.65-.04.91l-.67.67c-.24.24-.28.6-.09.85,0,0,.23,1.54,2.03,3.34,1.84,1.84,3.29,1.97,3.29,1.97.25.19.61.15.85-.09l.67-.67c.26-.26.67-.28.91-.04l1.66,1.66c.24.24.22.65-.04.91,0,0-.1.1-1.09,1.09-1.15,1.15-5.37-1.05-7.72-3.41-2.36-2.36-4.56-6.57-3.41-7.72.99-.99,1.08-1.08,1.09-1.09.26-.26.67-.28.91-.04Z"/></g></svg>'
                    )
                    left center / 100% auto no-repeat;
            }
        }
        .freetel {
            position: relative;
            margin: 0;
            &::before {
                width: 23px;
                height: 15px;
                background: svgUrlFunc(
                        '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 22.76 14.93"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><g id="_レイヤー_1-2"><path class="cls-1" d="m0,0v2.33c1.79,0,3.46.35,4.9.92.27.11.53.22.78.34.58-.28,1.2-.52,1.85-.71,1.18-.35,2.48-.55,3.85-.55,1.8,0,3.47.34,4.92.92.27.11.53.22.78.34.58-.28,1.2-.52,1.85-.71,1.17-.35,2.46-.55,3.83-.55V0H0Z"/><path class="cls-1" d="m14.25,8.83c0,.59.18,1.13.48,1.58.31.45.74.81,1.25,1.03.34.14.71.22,1.1.22.59,0,1.13-.18,1.58-.48.45-.31.81-.74,1.03-1.25.14-.34.22-.71.22-1.1,0-.28-.06-.58-.18-.89-.12-.31-.31-.64-.56-.96-.48-.62-1.19-1.23-2.08-1.74-.68.39-1.26.84-1.71,1.31-.48.5-.81,1.01-.97,1.49-.1.27-.14.54-.14.79Z"/><path class="cls-1" d="m19.85,5.53c.59.62,1.04,1.3,1.3,2.02.15.41.23.84.23,1.27,0,.89-.27,1.72-.73,2.4-.46.69-1.12,1.23-1.89,1.56-.51.22-1.08.34-1.67.34-.89,0-1.72-.27-2.4-.73-.69-.46-1.23-1.12-1.56-1.89-.22-.51-.34-1.08-.34-1.67,0-.65.18-1.29.49-1.88.31-.6.75-1.16,1.3-1.67.28-.27.59-.52.93-.76-.22-.08-.46-.16-.69-.23-1.05-.31-2.21-.49-3.43-.49-1.51,0-2.91.27-4.13.72.44.32.84.66,1.19,1.02.59.62,1.04,1.3,1.3,2.02.15.41.23.84.23,1.27,0,.89-.27,1.72-.73,2.4-.46.69-1.12,1.23-1.89,1.56-.51.22-1.08.34-1.67.34-.89,0-1.72-.27-2.4-.73-.69-.46-1.23-1.12-1.56-1.89-.22-.51-.34-1.08-.34-1.67,0-.65.18-1.29.49-1.88.31-.6.75-1.16,1.3-1.67.28-.27.59-.52.93-.76-.22-.08-.46-.16-.69-.23-1.04-.31-2.19-.49-3.41-.49v11.14h22.76V3.8c-1.5,0-2.89.27-4.1.72.44.32.84.66,1.19,1.02Z"/><path class="cls-1" d="m2.85,8.83c0,.59.18,1.13.48,1.58.31.45.74.81,1.25,1.03.34.14.71.22,1.1.22.59,0,1.13-.18,1.58-.48.45-.31.81-.74,1.03-1.25.14-.34.22-.71.22-1.1,0-.28-.06-.58-.18-.89-.12-.31-.31-.64-.56-.96-.48-.62-1.19-1.23-2.09-1.74-.68.39-1.26.84-1.71,1.31-.48.5-.81,1.01-.97,1.49-.1.27-.14.54-.14.79Z"/></g></svg>'
                    )
                    left center / 100% auto no-repeat;
            }
        }
        .opentime {
            margin: 23px 0;
            font-size: calc(10 / 16 * $font-size-base);
            letter-spacing: 0.26em;
            line-height: 1;
        }
        &__btn {
            @include c-contact-button();
        }
    }

    &__content {
        padding: 2px 0 13px 80px;
        @include media-down(lg) {
            padding-left: 60px;
        }
        @include media-up(md) {
            border-left: solid 1px rgba(255, 255, 255, 0.5);
        }
        @include media-down(md) {
            padding-top: 32px;
            padding-left: 0;
            border-top: solid 1px rgba(255, 255, 255, 0.5);
        }
    }

    &__companyName {
        margin-bottom: 20px;
        font-size: calc(24 / 16 * $font-size-base);
        font-family: 'Hannari', $font-family-serif;
        color: #fff;
        letter-spacing: 0.16em;
        line-height: 1;
        text-decoration: none;
    }
    &__companyBody {
        margin-bottom: 45px;
        color: #fff;
        dl {
            line-height: 1;
            dt {
                margin-bottom: 12px;
                font-size: calc(13 / 16 * $font-size-base);
                font-feature-settings: 'palt';
                font-weight: bold;
                letter-spacing: 0.18em;
            }
            dd {
                font-size: calc(11 / 16 * $font-size-base);
                letter-spacing: 0.21em;
            }
        }
    }
    &__address {
        margin-bottom: 40px;
        font-size: calc(11 / 16 * $font-size-base);
        font-feature-settings: 'palt';
        color: #fff;
        letter-spacing: 0.21em;
        line-height: calc(21 / 11);
        @include media-down(sm) {
            margin-bottom: 35px;
        }
        // .zip,
        // .addr2 {
        //     display: none;
        // }
        .addr1 {
            @include media-down(sm) {
                display: block;
            }
        }
        .telNum {
            display: flex;
            align-items: center;
            // @include media-down(sm) {
            //     font-feature-settings: inherit;
            // }
            .slash {
                margin-inline: 7px;
            }
        }
        .tel,
        .fax,
        .opentime {
            display: block;
        }

        .btn-link {
            font-weight: normal;
            margin-top: 3px;
            padding-left: 0;
            padding-right: 8px;
            padding-bottom: 4px;
            font-size: calc(12 / 16 * $font-size-base);
            font-family: 'Roboto', $font-family-sans-serif;
            border-bottom: 1px solid;
            letter-spacing: 0.18em;
            transition: $transition-base;
            &:hover,
            &:focus {
                text-decoration: none;
                color: #d6c29a;
            }
        }
    }

    &__bottom {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-top: 30px;
        @include media-down(sm) {
            margin-top: 20px;
        }
    }

    &__copyright,
    &__login {
        display: block;
        font-size: calc(10 / 16 * $font-size-base);
        text-align: center;
        letter-spacing: 0.15em;
        text-indent: 0.15em;
        color: rgba(255, 255, 255, 0.6);
        font-feature-settings: 'palt';
    }

    &__copyright {
        @include media-down(md) {
            margin-left: -1.3em;
            margin-right: -1.3em;
        }
        .en {
            font-family: 'Marcellus', $font-family-serif;
            font-weight: 400;
        }
    }

    &__login {
        .icon {
            // fill: rgba(255, 255, 255, 0.6);
        }
    }
}

.rwd002-footerNav {
    display: flex;
    flex-wrap: wrap;
    @include media-up(lg) {
        // grid-template: 1fr auto / 2fr 1fr;
        // flex-basis: 60%;
    }
    @include media-down(lg) {
        // display: flex;
        // flex-wrap: wrap;
    }
    @include media-down(md) {
        display: block;
    }
    &__list,
    &__sublist {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    &__list {
        display: flex;
        flex-wrap: wrap;
        font-size: calc(12 / 16 * $font-size-base);
        font-family: $font-family-serif;
        // color: darken($white, 20%);
        color: #fff;
        @include media-up(md) {
            // column-count: 2;
            // column-gap: 1em;
        }
        @include media-up(lg) {
            // grid-column: 1;
            // grid-row: 1 / span 2;
            // column-gap: 0;
        }
        @include media-down(lg) {
            // margin-right: 2.5em;
        }
        @include media-down(md) {
            // flex-grow: 1;
            // margin-right: 0;
            // margin-left: -0.8em;
            // margin-right: -0.8em;
            // margin-bottom: 1.5em;
        }
    }
    &__sublist {
        display: flex;
        align-items: center;
        font-family: 'Roboto', $font-family-sans-serif;
        font-size: calc(10 / 16 * $font-size-base);
        // color: darken($white, 40%);
        color: rgba(255, 255, 255, 0.6);
        // @include media-up(lg) {
        //     grid-column: 2;
        //     grid-row: 1;
        // }
        @include media-down(md) {
        }
    }
}

.rwd002-footerNav-item {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 14px;
    margin-right: 23px;
    @include media-down(md) {
        margin-bottom: 12px;
        margin-right: 20px;
        // position: relative;
        // border: solid darken($white, 50%);
        // border-width: 1px 0;
        // & + & {
        //     border-top-width: 0;
        // }
    }
    &__link {
        transition: $transition-base;
        position: relative;
        display: inline-block;
        padding: 0;
        letter-spacing: 0.26em;
        line-height: 1;
        // font-feature-settings: 'palt';
        // font-weight: $font-weight-bold;
        &:hover,
        &:focus {
            text-decoration: underline;
            color: #d6c29a;
        }
        @include media-down(md) {
            // flex-grow: 1;
            // flex-basis: calc(100% - 40px);
            // padding: 0.55em 40px 0.55em 1.7em;
            // &:before {
            //     top: 1.25em;
            //     left: 0.7em;
            // }
        }
    }
}

.rwd002-footerNav-subitem {
    &__link {
        display: inline-block;
        // padding: 0.3em 0;
        // margin-bottom: 0.5em;
        color: rgba(255, 255, 255, 0.6);
        letter-spacing: 0.15em;
        // font-weight: $font-weight-bold;
        transition: $transition-base;
        &:hover,
        &:focus {
            color: rgba(214, 194, 154, 0.6);
        }
        @include media-down(lg) {
            // margin-bottom: 0;
            // margin-right: 2em;
        }
    }
    .slash {
        transform: translateY(1px);
        display: inline-block;
        margin-inline: 8px;
    }
}

.rwd002-footer-subnav {
    @include media-up(md) {
        display: none;
    }
    &__tgl {
        @extend .btn;

        &:focus {
            box-shadow: none;
        }

        @include media-up(md) {
            display: none;
        }
        @include media-down(md) {
            position: relative;
            flex-basis: 40px;
            &:before,
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: calc(50% - 5px);
                width: 10px;
                height: 1px;
                background-color: darken($white, 50%);
                transition: transform 0.2s ease-in-out;
            }

            &:after {
                transform: rotate(-90deg);
            }

            &.is-active {
                &:before {
                    transform: rotate(180deg);
                }
                &:after {
                    transform: rotate(0deg);
                }
            }
        }
    }
    &__list {
        list-style: none;
        @include media-down(md) {
            padding-left: 1.7em;
            padding-bottom: 1em;
        }
    }
}

.rwd002-footer-subnav-item {
    &__link {
        @include media-down(md) {
            display: block;
            padding: 0.2em 0;
        }
    }
}

.rwd002-footer-search {
    margin-top: 2em;
    @include media-down(lg) {
        width: 100%;
        margin-top: 1.5em;
        margin-bottom: 3em;
    }
    &__inner {
        position: relative;
        max-width: 320px;
        @include media-down(md) {
            margin-left: -0.8em;
            margin-right: -0.8em;
        }
    }
    &__input {
        @extend .form-control;
        padding-right: 2.2em;
        padding-top: 0.5em;
        padding-bottom: 0.5em;
        background-color: tint($dark, 10%);
        transition: $transition-base;
        border-width: 0;
    }
    &__btn {
        @extend .btn;
        position: absolute;
        top: 0;
        bottom: 0;
        right: calc(100% - 2.2em);
        width: 2.2em;
        padding: 0 0.5em;
        pointer-events: none;
        transition: $transition-base;
        > svg {
            width: 1em;
            height: 100%;
            fill: tint($dark, 30%);
        }
    }
    &__btn:focus,
    &__input:focus + &__btn {
        pointer-events: auto;
        right: 0;
    }
}
