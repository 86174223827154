.is--feature.is--article-1 {
    .rwd002-container {
        margin-top: 0;
    }
}
.is--feature.is--article-2 {
    .rwd002-container {
        @include media-down(md) {
            margin-top: 0;
        }
    }
}

.feature {
    &__container {
        padding-inline: map-get($spacers, 3);
    }
    &__inner {
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    &__lead {
        width: 100%;
        max-width: 845px;
        margin-inline: auto;
        margin-bottom: 50px;
        @include media-up(lg) {
            display: flex;
            justify-content: space-between;
        }
        @include media-down(md) {
            margin-bottom: 15px;
        }
        ._title {
            margin: 0;
            font-size: calc(24 / 16 * $font-size-base);
            font-family: 'Hannari', $font-family-serif;
            color: $text-color;
            letter-spacing: 0.2em;
            line-height: calc(42 / 24);
            @include media-down(lg) {
                margin-bottom: 15px;
            }
            @include media-down(md) {
                font-size: calc(20 / 16 * $font-size-base);
                letter-spacing: 0.16em;
            }
        }
        ._text {
            width: 100%;
            margin: 0;
            font-size: calc(14 / 16 * $font-size-base);
            font-family: $font-family-serif;
            color: $text-color;
            letter-spacing: 0.14em;
            line-height: calc(30 / 14);
            @include media-up(lg) {
                max-width: 447px;
            }
        }
        &__btnwrap {
            @include media-up(lg) {
                display: none;
            }
            @include media-down(lg) {
                display: flex;
                justify-content: flex-end;
                margin-top: 25px;
            }
        }
        &__btn {
            @include c-more-button();
        }
    }
    &__title {
        @include c-block-localTitle();
    }
    &__reservationBtnwrap {
        display: flex;
        justify-content: center;
    }
    &__reservationBtn {
        @include c-reservation-button();
    }
}

.feature-point {
    overflow: hidden;
    padding-top: 50px;
    padding-bottom: 110px;
    @include media-down(md) {
        padding-top: 25px;
        padding-bottom: 90px;
    }
    &__inner {
        position: relative;
        width: 100%;
        max-width: 960px;
        margin-inline: auto;
    }
    &__itemBlock {
        position: relative;
        display: grid;
        justify-content: space-between;
        align-items: center;
        z-index: 1;
        @media (max-width: 980px) {
            display: block;
        }

        &:not(:last-of-type) {
            margin-bottom: 80px;
            @include media-down(md) {
                margin-bottom: 70px;
            }
        }
        &._odd {
            grid-template-columns: 500px 390px;
            @include media-down(xl) {
                grid-template-columns: 440px 450px;
            }
        }
        &._even {
            grid-template-columns: 390px 500px;
            @include media-down(xl) {
                grid-template-columns: 450px 440px;
            }
        }
    }
    &__visual {
        display: flex;
        flex-direction: column;
        @media (max-width: 980px) {
            flex-direction: inherit;
            justify-content: center;
            align-items: flex-start;
            margin-bottom: 35px;
        }
        @include media-down(md) {
            display: none;
        }
        ._odd & {
            order: 2;
            align-items: flex-start;
        }
        ._even & {
            align-items: flex-end;
        }
        ._image {
            width: calc(((100vw - 960px) / 2) + 390px);
            @include media-down(xl) {
                width: calc(((100vw - 960px) / 2) + 450px);
            }
            @media (max-width: 1017px) {
                width: 108%;
            }
            @media (max-width: 980px) {
                width: 613px;
            }
            img {
                object-fit: cover;
                width: 100%;
                height: 100%;
                @media (max-width: 980px) {
                    object-fit: inherit;
                }
            }
        }
    }
    .sp-img {
        margin-bottom: 20px;
        img {
            max-width: 100%;
            height: auto;
        }
    }
    &__body {
        width: 100%;
        ._odd & {
            order: 1;
        }
    }
    &__title {
        position: relative;
        margin-bottom: 50px;
        font-size: calc(25 / 16 * $font-size-base);
        font-family: 'Hannari', $font-family-serif;
        color: #2a2c2c;
        letter-spacing: 0.14em;
        line-height: 1;
        @include media-down(md) {
            margin-bottom: 30px;
            font-size: calc(18 / 16 * $font-size-base);
        }
        ._border {
            display: block;
            width: max-content;
            margin-bottom: 6px;
            padding: 14px 0;
            border-bottom: solid 1px #444444;
            @include media-down(md) {
                margin-bottom: 11px;
                padding: 8px 0;
            }
        }
        .icon {
            position: absolute;
            top: -25px;
            left: -30px;
            @media (max-width: 980px) {
                left: -10px;
            }
        }
    }
    &__lead {
        @include c-block-iconTitle();
    }
    &__text {
        margin-bottom: 30px;
        font-size: calc(14 / 16 * $font-size-base);
        font-family: $font-family-serif;
        color: #444444;
        letter-spacing: 0.1em;
        line-height: calc(30 / 14);
        @include media-down(md) {
            margin-bottom: 20px;
            letter-spacing: 0.12em;
        }
    }
    &__btnwrap {
        @include media-down(md) {
            display: flex;
            justify-content: flex-end;
        }
    }
    &__btn {
        @include c-more-button();
        width: max-content;
        padding-right: 30px;
    }
}
.feature-point-secondary {
    &__lead {
        @include media-up(lg) {
            display: grid;
            height: 484px;
        }
        &._odd {
            grid-template-columns: 490px 1fr;
        }
        &._even {
            grid-template-columns: 1fr 490px;
            ._head {
                order: 2;
            }
            ._image {
                order: 1;
            }
        }
        ._head {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            padding-inline: map-get($spacers, 3);
            background-color: #a1896f;
            @include media-down(lg) {
                height: 220px;
            }
            @include media-down(md) {
                height: 172px;
            }
        }
        ._image {
            position: relative;
            width: 100%;
            height: 100%;
            @include media-down(lg) {
                height: 350px;
            }
            @include media-down(md) {
                height: 250px;
            }
            @include media-down(sm) {
                height: 200px;
            }
            img {
                position: absolute;
                object-fit: cover;
                object-position: center;
                width: 100%;
                height: 100%;
            }
        }
        .feature-point__title {
            width: max-content;
            min-width: 252px;
            margin-bottom: 0;
            color: #fff;
            @include media-down(lg) {
            }
            @include media-down(sm) {
                width: 100%;
                letter-spacing: .12em;
            }
            ._border {
                width: 100%;
                border-bottom-color: #fff;
                @include media-down(lg) {
                    display: flex;
                    justify-content: center;
                }
                @include media-down(md) {
                    margin-bottom: 0 !important;
                }
            }
        }
    }
    &__grad {
        background: url(/assets/img/webp/feature/pho-feature-histoty-grad@2x.webp)
            no-repeat top center / 100% auto;
        @include media-down(sm) {
            background-image: url(/assets/img/webp/feature/pho-feature-histoty-grad_sp@2x.webp);
        }
    }
    &__inner {
        padding-top: 110px;
        padding-bottom: 110px;
        @include media-down(md) {
            padding-top: 50px;
            padding-bottom: 60px;
        }
    }
    &__item {
        display: grid;
        justify-content: space-between;
        align-items: center;
        gap: 70px;
        @include media-down(lg) {
            gap: 40px;
        }
        @include media-down(md) {
            display: block;
        }
        &:nth-of-type(odd) {
            grid-template-columns: 395px 1fr;
        }
        &:nth-of-type(even) {
            grid-template-columns: 1fr 395px;
            ._image {
                order: 2;
            }
            ._body {
                order: 1;
            }
        }
        &:not(:last-of-type) {
            margin-bottom: 35px;
            @include media-down(md) {
                margin-bottom: 45px;
            }
        }
        &._last {
            margin-bottom: 70px;
             @include media-down(md) {
                margin-bottom: 40px;
            }
        }
        ._image {
            width: 100%;
            @include media-down(md) {
                margin-bottom: 20px;
            }
            img {
                width: 100%;
                height: auto;
            }
        }
        ._body {
            .feature-point__lead {
                color: #6c6267;
            }
            .feature-point__text {
                color: #2a2c2c;
                @include media-down(md) {
                    line-height: calc(26 / 14);
                }
            }
        }
    }
    &__detail {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 60px;
        font-size: calc(14 / 16 * $font-size-base);
        color: #2a2c2c;
        text-align: center;
        letter-spacing: 0.06em;
        line-height: calc(32 / 14);
        border: solid 1px #2a2c2c;
        border-radius: 3px;
        @include media-down(md) {
            height: 50px;
        }
    }
    &__btnwrap {
        display: flex;
        justify-content: flex-end;
    }
}
.feature-message {
    overflow: hidden;
    position: relative;
    padding: 165px map-get($spacers, 3) 80px;
    background: url(/assets/img/webp/home/pho-products-back@2x.webp) no-repeat
        top 2px center / cover;
    @include media-down(lg) {
        padding-bottom: 70px;
    }
    @include media-down(md) {
        padding-top: 120px;
    }
    @include media-down(sm) {
        background-image: url(/assets/img/webp/home/pho-products-back_sp@2x.webp);
    }
    &__parts {
        ._top {
            position: absolute;
            left: 0;
            z-index: 1;
            width: 100vw;
        }
        ._top {
            top: 0;
            height: 68px;
            @include media-down(md) {
                width: 100%;
                height: 47px;
            }
        }
    }
    &__head {
        position: relative;
        margin-bottom: 35px;
        img {
            max-width: 100%;
            height: auto;
        }
        .feature__title {
            position: absolute;
            top: -30px;
            left: 45px;
            @include media-down(md) {
                top: -25px;
                left: 18px;
            }
        }
    }
    &__commont {
        width: 100%;
        max-width: 746px;
        margin-inline: auto;
        ._text {
            margin-bottom: 18px;
            font-size: calc(14 / 16 * $font-size-base);
            font-family: $font-family-serif;
            color: $text-color;
            letter-spacing: 0.14em;
            line-height: calc(30 / 14);
            @include media-down(sm) {
                margin: 0;
            }
        }
        .caption {
            width: max-content;
            margin-left: auto;
            font-family: $font-family-serif;
            color: $text-color;
            letter-spacing: 0.14em;
            line-height: 1;
            @include media-down(md) {
                display: flex;
            }
            @include media-down(sm) {
                transform: translateX(12px);
                margin-top: 8px;
            }
            .role {
                margin: 0;
                font-size: calc(11 / 16 * $font-size-base);
                @include media-down(md) {
                    margin-right: 18px;
                    padding-top: 8px;
                }
            }
            .directors-name {
                margin: 9px 0;
                font-size: calc(22 / 16 * $font-size-base);
                @include media-down(md) {
                    margin-top: 0;
                }
            }
            .directors-kana {
                margin: 0;
                font-size: calc(10 / 16 * $font-size-base);
                @include media-down(sm) {
                    transform: scale(0.87);
                    transform-origin: left top;
                }
            }
        }
    }
}
.feature-history {
    &__back {
        width: 100%;
        height: 484px;
        margin-left: auto;
        background-attachment: fixed;
        background-image: url(/assets/img/webp/feature/pho-feature-histoty-back@2x.webp);
        background-position: center;
        background-size: cover;
        @media (max-width: 1200px) {
            background-attachment: inherit;
            background-image: url(/assets/img/webp/feature/pho-feature-histoty-back_sp@2x.webp);
        }
        @include media-down(md) {
            height: 330px;
        }
        @include media-down(sm) {
            width: 100%;
            height: 200px;
        }
    }
    &__container {
        background: url(/assets/img/webp/feature/pho-feature-histoty-grad@2x.webp)
            no-repeat top center / 100% auto;
    }
    &__inner {
        position: relative;
        width: 100%;
        max-width: 1200px;
        margin-inline: auto;
        padding-top: 115px;
        padding-bottom: 115px;
        @include media-down(md) {
            padding-top: 55px;
            padding-bottom: 55px;
        }
    }
    .feature__title {
        position: absolute;
        top: -185px;
        right: 0;
        @include media-down(md) {
            top: -130px;
        }
    }
    &__title {
        @include c-block-subTitle();
        margin-bottom: 35px;
        padding-bottom: 23px;
        font-size: calc(28 / 16 * $font-size-base);
        font-family: 'Hannari', $font-family-serif;
        font-feature-settings: 'palt';
        color: #a1896f;
        letter-spacing: 0.1em;
        line-height: calc(36 / 28);
        @include media-down(md) {
            margin-bottom: 22px;
            padding-bottom: 15px;
            font-size: calc(20 / 16 * $font-size-base);
        }
    }
    &__item {
        @include media-up(lg) {
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 50px;
        }
        &:nth-of-type(odd) {
            flex-direction: row-reverse;
            padding-right: 120px;
            @include media-down(xl) {
                padding-right: 40px;
            }
            @include media-down(lg) {
                padding-right: 0;
            }
        }
        &:nth-of-type(even) {
            padding-left: 120px;
            @include media-down(xl) {
                padding-left: 40px;
            }
            @include media-down(lg) {
                padding-left: 0;
            }
        }
        &:not(:last-of-type) {
            margin-bottom: 115px;
            @include media-down(lg) {
                margin-bottom: 55px;
            }
        }
        ._body {
            width: 100%;
            @include media-up(lg) {
                max-width: 545px;
            }
            @include media-down(lg) {
                margin-bottom: 25px;
            }
        }
        ._text {
            font-size: calc(14 / 16 * $font-size-base);
            font-family: $font-family-serif;
            color: #2a2c2c;
            letter-spacing: 0.14em;
            line-height: calc(30 / 14);
            @include media-down(md) {
                letter-spacing: 0.12em;
            }
            &:not(:last-of-type) {
                margin-bottom: 20px;
            }
            ._small {
                display: block;
                font-size: calc(10 / 16 * $font-size-base);
                line-height: calc(20 / 10);
            }
        }
        ._img {
            img {
                max-width: 100%;
                height: auto;
            }
            ._text {
                @include media-down(md) {
                    margin-top: 15px;
                }
            }
            @include media-down(lg){
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
    }
}
.feature-btn__list {
    @include media-up(md) {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
    }
    @include media-down(md) {
        display: block;
    }
}
.feature-btn__link {
    transition: $transition-base;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 120px;
    font-size: calc(18 / 16 * $font-size-base);
    font-family: 'Hannari', $font-family-serif;
    text-decoration: none !important;
    color: #fff !important;
    letter-spacing: 0.2em;
    line-height: 1;
    @include media-down(md) {
        justify-content: flex-start;
        width: 100%;
        height: 53px;
        padding-left: 34px;
        font-size: calc(14 / 16 * $font-size-base);
    }
    @media (hover: hover) {
        &:hover {
            &::before {
                right: 15px;
            }
        }
    }
    &::before {
        content: '';
        transition: $transition-base;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 21px;
        width: 10px;
        height: 4px;
        margin: auto;
        background: svgUrlFunc(
                '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.81 5.21"><defs><style>.cls-1{fill:none;stroke:#fff;stroke-miterlimit:10;}</style></defs><g id="_レイヤー_1-2"><polyline class="cls-1" points="0 4.71 24.55 4.71 19.94 .36"/></g></svg>'
            )
            right center / auto 100% no-repeat;
        @include media-down(md) {
            right: 11px;
        }
    }
    span {
        position: relative;
        &::before {
            content: '';
            transition: $transition-base;
            position: absolute;
            top: 0;
            bottom: 0;
            left: -21px;
            width: 13px;
            height: 13px;
            margin: auto;
            background: svgUrlFunc(
                    '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12.17 12.17"><defs><style>.cls-1{fill:#fff;stroke-width:0px;}</style></defs><g id="_文字"><rect class="cls-1" x="4.09" y=".45" width="3.99" height="3.99" rx=".92" ry=".92" transform="translate(.06 5.02) rotate(-45)"/><rect class="cls-1" x="4.09" y="7.73" width="3.99" height="3.99" rx=".92" ry=".92" transform="translate(-5.1 7.15) rotate(-45)"/><rect class="cls-1" x="7.73" y="4.09" width="3.99" height="3.99" rx=".92" ry=".92" transform="translate(7.15 -5.1) rotate(45)"/><rect class="cls-1" x=".45" y="4.09" width="3.99" height="3.99" rx=".92" ry=".92" transform="translate(5.02 .06) rotate(45)"/></g></svg>'
                )
                center / auto 100% no-repeat;
            @include media-down(md) {
                left: -12px;
                width: 7px;
                height: 7px;
            }
        }
    }
    &.history {
        background-color: #33566e;
        @media (hover: hover) {
            &:hover {
                background-color: darken(#33566e, 10%);
            }
        }
    }
    &.feature {
        background-color: #a1896f;
        @media (hover: hover) {
            &:hover {
                background-color: darken(#a1896f, 10%);
            }
        }
    }
    &.products {
        background-color: #7b4e59;
        @media (hover: hover) {
            &:hover {
                background-color: darken(#7b4e59, 10%);
            }
        }
    }
}
